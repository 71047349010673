import { Tooltip } from '@mui/material'
import { useAuth } from 'hooks/useAuth'
import EntitiesButton from 'organisms/sidebar/components/entitiesButton'
import EntitiesTitle from 'organisms/sidebar/components/entitiesTitle'
import { getMenus } from 'organisms/sidebar/config/sidebar.config'
import React, { useCallback, useMemo } from 'react'

const SideBarMenuRenderer = ({
  activeLink,
  onActiveStateHandler,
  open,
  isExtendedFieldsVisible,
  setIsExtendedFieldsVisible,
}: SideBarMenuRendererProps) => {
  const { user } = useAuth()
  const onSubFieldClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      setIsExtendedFieldsVisible(!isExtendedFieldsVisible)
    },
    [isExtendedFieldsVisible, setIsExtendedFieldsVisible]
  )

  const menus = useMemo(
    () =>
      getMenus({
        hideICA: !(user?.flags_enabled && user?.flags_enabled?.includes('INTERCOMPANY_AGREEMENT_PREMIUM_FEATURES')),
        hideChat: !(user?.flags_enabled && user?.flags_enabled?.includes('ERICA_CHAT')),
      }),
    [user?.flags_enabled]
  )

  return (
    <ul className="flex flex-col justify-center gap-2 w-full mt-7">
      {menus.map((menu, index) => (
        <div key={index}>
          {menu.type !== 'Entities' && <EntitiesTitle menu={menu} open={open} />}
          <ul className="flex flex-col gap-4">
            {menu.fields.map((field, index) => {
              if (field.hide) return null
              return (
                <div key={index} className="flex items-center ">
                  <div className="flex flex-col items-center w-full">
                    <Tooltip title={!open ? field.title : ''} placement="right">
                      <div className="flex items-center justify-center max-w-[11.75rem]  gap-[0.75rem]">
                        <EntitiesButton
                          open={open}
                          activeLink={activeLink}
                          field={field}
                          onActiveStateHandler={onActiveStateHandler}
                          isSubFieldIncluded={activeLink?.includes(field.path) && !field.subfields}
                          onSubFieldClick={onSubFieldClick}
                          isExtendedFieldsVisible={isExtendedFieldsVisible}
                        />
                      </div>
                    </Tooltip>

                    {field.subfields &&
                      isExtendedFieldsVisible &&
                      open &&
                      field.subfields.map((subfield, index) => (
                        <div key={index} className="flex mt-2 items-center gap-1 w-full">
                          <EntitiesButton
                            open={open}
                            activeLink={activeLink}
                            field={subfield}
                            onActiveStateHandler={onActiveStateHandler}
                            isSubFieldIncluded={!!field?.subfields?.length}
                            isExtendedFieldsVisible={isExtendedFieldsVisible}
                            className="pl-12"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )
            })}
          </ul>
        </div>
      ))}
    </ul>
  )
}

interface SideBarMenuRendererProps {
  activeLink?: string
  onActiveStateHandler: (path: string) => VoidFunction
  open: boolean
  setIsExtendedFieldsVisible: React.Dispatch<React.SetStateAction<boolean>>
  isExtendedFieldsVisible: boolean
}

export default SideBarMenuRenderer
