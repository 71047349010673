import axios from '../../index'
import {
  addComparabilityMetricPayload,
  addComparabilityMetricToTransactionPayload,
  ComparabilityJustification,
  ComparabilityMetric,
  editComparabilityFactorsPayload,
  editComparabilityJustificationPayload,
} from './types'

export const getComparabilityMetricsList = () => {
  return axios
    .get<ComparabilityMetric[]>(`/api/v1/org/{organization}/transaction/comparability_metric/`)
    .then(res => res.data)
}

export const addComparabilityMetric = (payload: addComparabilityMetricPayload) => {
  return axios
    .post<ComparabilityMetric>(`/api/v1/org/{organization}/transaction/comparability_metric/`, payload)
    .then(res => res.data)
}

export const addComparabilityMetricToTransaction = (payload: addComparabilityMetricToTransactionPayload) => {
  return axios
    .post(`/api/v1/org/{organization}/transaction/justification_of_comparability/`, payload, {
      headers: {
        'Content-Type': !!payload.justification || !!payload.comparability_file ? 'multipart/form-data' : undefined,
      },
    })
    .then(res => res.data)
}

export const getComparabilityJustificationList = (payload: { economic_analysis: number }) => {
  return axios
    .get<ComparabilityJustification[]>(`/api/v1/org/{organization}/transaction/justification_of_comparability/`, {
      params: payload,
    })
    .then(res => res.data)
}
export const editComparabilityJustification = ({ id, ...variables }: editComparabilityJustificationPayload) => {
  return axios
    .patch<ComparabilityJustification>(
      `/api/v1/org/{organization}/transaction/justification_of_comparability/${id}/`,
      variables,
      {
        headers: {
          'Content-Type':
            !!variables.justification || !!variables.comparability_file ? 'multipart/form-data' : undefined,
        },
      }
    )
    .then(res => res.data)
}

export const deleteComparabilityJustification = ({ id }: { id: number }) => {
  return axios
    .delete(`/api/v1/org/{organization}/transaction/justification_of_comparability/${id}/`)
    .then(res => res.data)
}

export const editComparabilityFactors = ({ id, ...variables }: editComparabilityFactorsPayload) => {
  return axios
    .patch(
      `/api/v1/org/{organization}/transaction/economic_analysis/${id}/`,
      {
        ...variables,
      },
      {
        headers: {
          'Content-Type': variables.comparability_factors_file instanceof File ? 'multipart/form-data' : undefined,
        },
      }
    )
    .then(res => res.data)
}
