import 'styles/index.css'
import 'react-toastify/dist/ReactToastify.css'

import { UserProvider } from '@auth0/nextjs-auth0/client'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from 'hooks/useAuth'
import { Inter } from 'next/font/google'
import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import store from 'store'
import ThemeProvider from 'styles/theme'

const inter = Inter({
  subsets: ['latin'],
  preload: true,
  adjustFontFallback: true,
  variable: '--inter-font',
})

import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'
import { ROUTES } from 'constants/routes'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Layout from 'organisms/layout'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { initializeAnalytics } from 'utils/analytics'

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  )

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const isOrgRoute = url.includes('/org/[orgId]/') || url.includes('/org/%5BorgId%5D/')
      const orgId =
        router.query?.orgId && !!Number(router.query?.orgId)
          ? (router.query?.orgId as string)
          : sessionStorage.getItem('orgId') || localStorage.getItem('orgId')

      if (isOrgRoute && !Number(orgId)) {
        router.push(ROUTES.HOME)
      }
      if (isOrgRoute && orgId && !!Number(orgId)) {
        router.push(url.replace('[orgId]', orgId).replace('%5BorgId%5D', orgId))
      }
    }

    initializeAnalytics()
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (router.isReady) {
      if (router.query?.orgId) {
        const orgId = router.query.orgId as string

        if (!!Number(orgId) && orgId !== sessionStorage.getItem('orgId')) {
          sessionStorage.setItem('orgId', orgId)
          localStorage.setItem('orgId', orgId)
        } else if (!Number(orgId)) {
          if (sessionStorage.getItem('orgId') || localStorage.getItem('orgId')) {
            router.replace({
              pathname: router.pathname,
              query: { ...router.query, orgId: sessionStorage.getItem('orgId') || localStorage.getItem('orgId') },
            })
          }
        }
      }
    }
  }, [router])

  return (
    <>
      <style jsx global>
        {`
          :root {
            --inter-font: ${inter.style.fontFamily};
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider>
            <DndProvider backend={HTML5Backend}>
              <main className={inter.className}>
                <ErrorBoundary>
                  <UserProvider>
                    <AuthProvider>
                      <Layout>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <ToastContainer autoClose={5000} />
                          <Component {...pageProps} />
                        </LocalizationProvider>
                      </Layout>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </AuthProvider>
                  </UserProvider>
                </ErrorBoundary>
              </main>
            </DndProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
