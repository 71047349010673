import { IconProps } from 'assets'
import React from 'react'

const Search = ({ className }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9825 12.425L15.5325 14.9675C15.6745 15.1083 15.7543 15.3 15.7543 15.5C15.7543 15.7 15.6745 15.8917 15.5325 16.0325C15.3917 16.1745 15.2 16.2543 15 16.2543C14.8 16.2543 14.6083 16.1745 14.4675 16.0325L11.925 13.4825C10.8768 14.3052 9.58249 14.7517 8.25 14.75C4.93629 14.75 2.25 12.0637 2.25 8.75C2.25 5.43629 4.93629 2.75 8.25 2.75C11.5637 2.75 14.25 5.43629 14.25 8.75C14.2517 10.0825 13.8052 11.3768 12.9825 12.425ZM8.25 4.25C5.76472 4.25 3.75 6.26472 3.75 8.75C3.75 11.2353 5.76472 13.25 8.25 13.25C10.7353 13.25 12.75 11.2353 12.75 8.75C12.75 6.26472 10.7353 4.25 8.25 4.25Z"
        fill="#1E293B"
      />
    </svg>
  )
}

export default Search
